import React from "react";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";

const Apps = () => {
    const navigate = useNavigate();
    const handleAppClick = () => {
        navigate("/math")
    }
    return (
        <div className="p-16 h-[90vh] w-full">
            <div className="font-bold text-xl mb-5">Apps</div>
            <div onClick={handleAppClick} className="flex flex-col gap-2 border rounded-md border-borderColor w-1/2 p-5 hover:bg-gray-300 min-w-64 cursor-default">
                <div className="font-semibold">
                    Secure 60
                </div>
                <div className="">
                    Ace your SSLC exams effortlessly with "Secure 60"! Our focused app guarantees a solid 60 marks when you complete the tailored courses. Spend just 75 minutes for a quick and effective revision, ensuring you're well-prepared for success.
                </div>
                <div >
                    <button className="bg-green-500 rounded-md px-4 py-1">
                        Enroll and Start
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Apps;