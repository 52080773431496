import { addToCollection, getCollectionRef, getUserDoc } from "./basicOperations";

export const addNewUser = async (email: string) => {
    const userCollection = await getCollectionRef("users");
    const userDoc = await getUserDoc(userCollection, email);
    if (userDoc === null){
        addToCollection(
            userCollection,
            {
                email,
                payments:{
                    status: false,
                    payment_timestamp: null
                }
            }
        )
    }
};

