import React, { useState } from "react";
import { UserAuth } from "../context/AuthContext";
import { Link, useLocation } from "react-router-dom";
import { EllipsisVerticalIcon, Bars3Icon } from "@heroicons/react/24/solid";

const Navbar = ({ userOptions, handleUserOptions, sideBar, handleSideBar }: { userOptions: boolean, handleUserOptions: any, sideBar: boolean, handleSideBar: any }) => {

    const { user, logOut, sessionToken } = UserAuth();
    const location = useLocation();

    const handleSignOut = async () => {
        try {
            await logOut();
            handleUserOptions();
        } catch (error) {
            console.log(error);
        }
    }
    
    if (user)
        return (
            <div className="flex relative items-center border border-borderColor h-[10vh]">
                <div className="flex h-full gap-x-10 flex-nowrap items-center justify-between w-full px-5 lg:border-0 lg:mx-0 overflow-hidden">
                    <div className="w-auto font-semibold inline-flex text-nowrap text-LogoColor"><Link to={'/'} className="cursor-default text-center">MATH UNLIMITED</Link></div>
                    <div className="h-full flex items-center gap-4 text-navPrimary font-medium">
                        <div className="hidden md:block text-sm leading-6 hover:text-gray-900"><Link to={'/policies'}>Terms & Privacy</Link></div>
                        <div className="hidden md:block text-sm leading-6 hover:text-gray-900"><Link to={'/math'}>Lessons</Link></div>
                        <div className="hidden md:block">{user === null ? <></> : (user.email === "onkarmadli@gmail.com" || user.email === "malinisamrudh@gmail.com") && <div className="text-sm leading-6 hover:text-gray-900"><Link to={'/admin'}>Admin</Link></div>}</div>
                        {
                            user &&
                            <div className="hidden md:block">
                                <EllipsisVerticalIcon className="h-8 w-8 rounded-full cursor-pointer" onClick={handleUserOptions} />
                            </div>
                        }
                        {
                            user &&
                            <img src={user.photoURL} className="h-8 w-8 rounded-full" />
                        }
                        {
                            user?.displayName && userOptions &&
                            <div className={`z-10 grid absolute bg-white shadow-lg border border-[#d8d8d9] top-full right-10 transition-all duration-300 ease-in-out ${userOptions ? 'grid-rows-[1fr] opacity-100' : 'grid-rows-[0fr] opacity-0'}`}>
                                <ul className="flex flex-col">
                                    <li className="p-2"><button>View Profile</button></li>
                                    <li className="p-2"><button onClick={handleSignOut}>Sign Out</button></li>
                                </ul>
                            </div>
                        }
                        {
                            <div className={`h-full items-center ${location.pathname !== "/topic" ? "flex md:hidden" : "flex"}`}>
                                <Bars3Icon className="h-6 w-6 rounded-full cursor-pointer" onClick={handleSideBar} />
                            </div>
                        }
                    </div>
                </div>

            </div>
        )
}

export default Navbar;