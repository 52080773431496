import axios from "axios";
import { useState } from "react";
import { UserAuth } from "../context/AuthContext";

const useFileUploader = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { sessionToken } = UserAuth();
  const uploadFile = async (
    filePath: string,
    fileName: string,
    fileData: any
  ) => {
    try {
      setLoading(true);

      const formData = new FormData();
      formData.append("file", fileData);

      await axios.post('https://replacefile-dn5gf2nnra-uc.a.run.app/' + filePath + fileName, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          "Authorization": "Bearer " + sessionToken,
          "Access-Control-Allow-Origin": "*"
        }
      });
      console.log("File updated Successfully!");
    } catch (error) {
      console.log("Error updating file");
    } finally {
      setLoading(false);
    }
  };

  return { uploadFile, loading }
};

export default useFileUploader;
