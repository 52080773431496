import React from "react";
import { UserAuth } from "../context/AuthContext";
import Home from "./Home";
import { useLocation } from "react-router-dom";

const SideBar = ({sideBar, handleSideBar}:{sideBar:boolean; handleSideBar: any}) => {
    const { logOut } = UserAuth();
    const location = useLocation();
    const handleSignOut = async () => {
        try {
            await logOut();
            handleSideBar();
        } catch (error) {
            console.log(error);
        }
    }

    if (location.pathname === "/topic")
        return (
            <div className={`absolute w-full md:w-[35vw] h-[90vh] right-0 top-[10vh] bg-white ${sideBar? "flex" : "hidden"}`}>
                <div className="hidden md:block overflow-auto shadow-[-10px_0px_8px_0px_#00000024] w-full px-2">
                    <Home isSub={true} />
                </div>
                <div className="flex md:hidden justify-center overflow-auto shadow-[-10px_0px_8px_0px_#00000024] w-full px-2">
                    <div className="flex flex-col gap-2 mt-2 w-full text-center">
                        <div className="p-3 hover:bg-gray-300">
                            View Profile
                        </div>
                        <div className="p-3 hover:bg-gray-300" onClick={handleSignOut}>
                            Sign Out
                        </div>
                    </div>
                </div>
            </div>
        )
    else 
        return (
            <div className={`absolute w-full md:w-[35vw] h-[90vh] right-0 top-[10vh] bg-white ${sideBar? "flex" : "hidden"} md:hidden `}>
                <div className="flex justify-center overflow-auto shadow-[-10px_0px_8px_0px_#00000024] w-full px-2">
                    <div className="flex flex-col gap-2 mt-2 w-full text-center">
                        <div className="p-3 hover:bg-gray-300">
                            View Profile
                        </div>
                        <div className="p-3 hover:bg-gray-300" onClick={handleSignOut}>
                            Sign Out
                        </div>
                    </div>
                </div>
            </div>
        )
}

export default SideBar;