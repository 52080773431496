import React from "react"
import Modal from "./Modal"

const NotSubscribedModal = ({visible, onClose}) => {
    const ModalContent = () => {
        return <div>
            Not Subscribed!
        </div>
    }
    return <Modal visible={visible} onClose={onClose} content={<ModalContent />} />
}

export default NotSubscribedModal;