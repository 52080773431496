import React from "react";
import ReactMarkdown from 'react-markdown'

const Policies = () => {
    return (
        <div className="container mx-auto mb-8 p-8 bg-white rounded shadow-md">
            <h2 className="text-3xl font-bold mb-4">Privacy Policy</h2>
            <p className="pb-5">Last updated on: 9th March 2024, 22:00 IST</p>
            <p className="mb-4">
                Thank you for choosing Math Unlimited. We are committed to protecting
                your personal information and your right to privacy. Math Unlimited
                adheres to ethical standards in gathering, using, and safeguarding any
                information you provide. Please read this privacy policy ('Policy')
                carefully before using the Application, Website, our services, and
                products, along with the Terms of Use ('ToU') provided on the
                Application and the Website. Your use of the Website, Application, or
                services in connection with the Application, Website, or products
                ('Services'), or registrations with us through any modes or usage of any
                products including through SD cards, tablets, or other
                storage/transmitting device shall signify your acceptance of this Policy
                and your agreement to be legally bound by the same. If you do not agree
                with the terms of this Policy, do not use the Website, Application our
                products or avail any of our Services.
            </p>

            <section className="mb-8">
                <h3 className="text-xl font-semibold mb-4">1. Information we collect</h3>
                <p className="mb-2">
                    a. Google OAuth2 Data: When you authenticate using your Google
                    credentials, we may collect and store the following information:
                </p>
                <ul className="list-disc ml-8">
                    <li>User ID</li>
                    <li>Email address</li>
                    <li>Name</li>
                    <li>Profile picture URL</li>
                    <li>Access token</li>
                    <li>Refresh token</li>
                </ul>
                <p className="mt-4">
                    b. How We Use Your Data: We use the collected Google OAuth2 data for
                    the following purposes:
                </p>
                <ul className="list-disc ml-8">
                    <li>User Authentication: To verify your identity and provide access
                        to our services.</li>
                    <li>Personalization: To personalize your experience and display
                        relevant content.</li>
                    <li>Communication: To send important notifications and updates.</li>
                </ul>
                <p className="mt-4">
                    c. Data Storage and Security:
                </p>
                <ul className="list-disc ml-8">
                    <li>We store Google OAuth2 data in a secure database.</li>
                    <li>Access to the database is restricted to authorized personnel only.</li>
                    <li>We implement industry-standard security measures to protect your data from unauthorized access, disclosure, alteration, and destruction.</li>
                </ul>
                <p className="mt-4">
                    d. Data Retention: We retain Google OAuth2 data for as long as necessary
                    to fulfill the purposes outlined in this privacy policy or as required
                    by law. If you wish to delete your account or request data removal,
                    please contact us at{' '}
                    <a href="mailto:mathunlimitedorg@gmail.com">mathunlimitedorg@gmail.com</a>
                    .
                </p>
                <p className="mt-4">
                    e. Third-Party Services: We may share your information with third-party
                    service providers who help us provide and improve our services.
                </p>
                <p className="mt-4">
                    f. Cookies: To enhance User experience, our Platform may use 'cookies'.
                    A cookie is a string of information that a website stores on a visitor’s
                    computer, and that the visitor’s browser provides to the website each
                    time the visitor returns for record-keeping purposes. You may choose to
                    set Your web browser to refuse cookies, or to notify You when cookies
                    are being sent; however, please note that in doing so, some parts of the
                    Platform may not function properly.
                </p>
            </section>

            <section className="mb-8">
                <h3 className="text-xl font-semibold mb-4">2. Security of Information Collected</h3>
                <p className="mb-4">
                    We take the security of your personal information seriously and use
                    appropriate technical and organizational measures to protect your
                    personal information against unauthorized or unlawful processing and
                    against accidental loss, destruction, or damage. Unfortunately, the
                    transmission of information via the internet is not completely
                    secure. Although we will do our best to protect your personal data,
                    we cannot guarantee the security of your data which is transmitted to
                    or from the Service. Any transmission is at your own risk. We keep
                    your personal information for no longer than is necessary for our
                    business purposes or for legal requirements.
                </p>
            </section>

            <section className="mb-8">
                <h3 className="text-xl font-semibold mb-4">3. Cross-border Data transfer</h3>
                <p className="mb-4">
                    Your information including any Personal Information is stored,
                    processed, and transferred in and to the Firebase (Google Cloud)
                    servers and Firestore databases located in India. If you use our
                    Platform from outside India, your information may be transferred to,
                    stored, and processed in India. By accessing our Platform or
                    otherwise giving us information, you consent to the transfer of
                    information to India.
                </p>
            </section>

            <section className="mb-8">
                <h3 className="text-xl font-semibold mb-4">4. Changes to the Privacy Policy</h3>
                <p className="mb-4">
                    We reserve the right to update this privacy policy. Any changes will
                    be communicated through our website or other appropriate channels.
                </p>
            </section>

            <section>
                <h3 className="text-xl font-semibold mb-4">5. Contact Information</h3>
                <p className="mb-4">
                    If you have any questions, concerns, or requests regarding this
                    privacy policy, please contact us at{' '}
                    <a href="mailto:mathunlimitedorg@gmail.com">mathunlimitedorg@gmail.com</a>
                    .
                </p>
            </section>
            <h2 className="text-3xl font-bold mt-10 mb-4">Terms and Conditions</h2>
            <p className="mb-4">
                Please read these Terms and Conditions ("Terms") carefully before using Math Unlimited(the "Service").
            </p>
            <section className="mb-8">
                <h3 className="text-xl font-semibold mb-4">1. Acceptance of Terms</h3>
                <p className="mb-4">
                    By accessing or using Math Unlimited, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use the Service.
                </p>
            </section>
            <section className="mb-8">
                <h3 className="text-xl font-semibold mb-4">2. User Accounts</h3>
                <ul className="list-disc ml-8">
                    <li>Account Creation: To access certain features of the Service, you may be required to create an account. You agree to provide accurate, current, and complete information during the registration process.</li>
                    <li>Account Security: You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</li>
                </ul>
            </section>
            <section className="mb-8">
                <h3 className="text-xl font-semibold mb-4">3. Payment and Subscription</h3>
                <ul className="list-disc ml-8">
                    <li>One-Time Payment: Math Unlimited offers content through a payment model. By making a payment, you agree to the specified terms and conditions regarding access to the content.</li>
                    <li>Payment Information: All payment information provided is accurate, and you have the legal right to use the chosen payment method.</li>
                </ul>
            </section>
            <section className="mb-8">
                <h3 className="text-xl font-semibold mb-4">4. Content and Use of the Service</h3>
                <ul className="list-disc ml-8">
                    <li>Intellectual Property: The content on Math Unlimited is protected by intellectual property laws. You may not use, reproduce, distribute, or create derivative works without explicit permission.</li>
                    <li>Prohibited Activities: You agree not to engage in any prohibited activities, including but not limited to: unauthorized access, data mining, or any action that could disrupt the functionality of the Service.</li>
                </ul>
            </section>
            <section className="mb-8">
                <h3 className="text-xl font-semibold mb-4">5. Termination</h3>
                <ul className="list-disc ml-8">
                    <li>Termination: We reserve the right to suspend or terminate your access to the Service at our sole discretion, without prior notice, for any reason, including if you breach these Terms.</li>
                    <li>Termination by User: You may terminate your account by discontinuing the use of the Service.</li>
                </ul>
            </section>
            <section className="mb-8">
                <h3 className="text-xl font-semibold mb-4">6. Changes to Terms</h3>
                <ul className="list-disc ml-8">
                    <li>Updates: We reserve the right to modify or replace these Terms at any time. The updated version will be effective upon posting on Math Unlimited. Continued use of the Service after such changes constitutes acceptance of the new Terms.</li>
                </ul>
            </section>
        </div>
    );
}

export default Policies;