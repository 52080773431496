import React, { useEffect, useState } from "react";
import useGetChapters from "../hooks/useGetChapters";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { ChevronUpIcon } from "@heroicons/react/24/solid";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import ChapterAccordions from "../components/Home/ChapterAccordion";
import YoutubeModal from "../components/Home/YoutubeModal";
import { useNavigate } from "react-router-dom";


const Home = ({ isSub }: { isSub?: boolean }) => {
    const { getChaptersList, chaptersList, loading } = useGetChapters();
    const [accordion, setAccordion] = useState("");
    const [selectedLanguage, setSelectedLanguage] = useState<"English" | "Kannada">("English")
    const [youtubeModal, setYoutubeModal] = useState<boolean>(false);
    const navigate = useNavigate();
    useEffect(() => {
        getChaptersList(selectedLanguage === "English" ? false : true);
    }, [selectedLanguage]);

    let classWidth: string | undefined;
    if (isSub) {
        classWidth = "w-full"
    } else {
        classWidth = "w-10/12 sm:w-6/12"
    }

    useEffect(() => {
        if (chaptersList === "Not Subscribed!")
            navigate("/checkout")
    }, [chaptersList])

    return (
        <div className={`flex flex-col items-center gap-1 ${isSub}?"":"mt-[10vh]"`}>
            <YoutubeModal visible={youtubeModal} onClose={() => setYoutubeModal(false)} />
            <div className={`${classWidth}  ${isSub} && flex gap-2 mt-4 justify-between mb-2 `}>
                <button className="inline-flex items-center px-4 py-2 rounded-md bg-slate-300 text-nowrap"
                    onClick={() => setYoutubeModal(true)}
                >How to use <QuestionMarkCircleIcon className="size-5" /></button>
                <div className={` dark:text-gray-800 inline-flex`}>
                    <button className={`px-4 py-2 rounded-l-md ${selectedLanguage === "English" ? "dark:bg-slate-300" : "dark:bg-gray-200"} cursor-pointer font-semibold`} onClick={() => setSelectedLanguage("English")}>English</button>
                    <button className={`px-4 py-2 rounded-r-md ${selectedLanguage !== "English" ? "dark:bg-slate-300" : "dark:bg-gray-200"} cursor-pointer font-semibold`} onClick={() => setSelectedLanguage("Kannada")}>ಕನ್ನಡ</button>
                </div>
            </div>
            {
                (loading || chaptersList === "Not Subscribed!") ? "Loading..." : chaptersList?.sort((a, b) => a.chapter_id - b.chapter_id).map((doc: any, index: number) => {
                    const docData = doc;

                    return (
                        <div key={docData.chapter_id} className={classWidth}>
                            <div className="flex border border-borderColor rounded-md justify-between items-center p-4  bg-white cursor-pointer shadow-xl text-xl" onClick={() => {
                                if (accordion === docData.chapter_id) {
                                    setAccordion("")
                                } else {
                                    setAccordion(docData.chapter_id)
                                }
                            }} >
                                <div className="font-bold text-ellipsis overflow-hidden ...">
                                    {index + 1}. {docData.name}
                                </div>
                                {accordion === docData.chapter_id ? <ChevronUpIcon className="size-5" /> : <ChevronDownIcon className="size-5" />}
                            </div>
                            <div className={`grid shadow-slate-200 shadow-inner overflow-hidden transition-all duration-300 ease-in-out cursor-pointer text-slate-950 ${accordion === docData.chapter_id ? 'grid-rows-[1fr] opacity-100' : 'grid-rows-[0fr] opacity-0'}`}>
                                <ChapterAccordions docData={docData ? docData : {}} kannada={selectedLanguage === "Kannada" ? true : false} />
                            </div>
                        </div>
                    )
                })
            }
        </div>)
}

export default React.memo(Home);