import React, { useEffect } from "react";
import GoogleButton from "react-google-button"
import { UserAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { addNewUser } from "../firestore/addNewUser";


const Signin = () => {

    const { googleSignIn, user, sessionToken } = UserAuth();
    const navigate = useNavigate();
    const handleGoogleSignIn = async () => {
        try {
            await googleSignIn();
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        try {
            if (user.email !== null && user.email !== undefined && sessionToken !== null) {
                addNewUser(user.email);
                navigate("/")
            }
        } catch (error) {
            console.log("Failed to add user");
        }
    }, [user, sessionToken]);

    return (

        <div className="flex overflow-auto items-center px-16 flex-col w-[100vw] h-[90vh] text-base font-semibold">
            <div className="md:font-semibold md:text-5xl text-2xl font-semibold py-10">
                Welcome to Math Unlimited!
            </div>
            <div className="py-5">
                Unlock the Infinite World of Math with Math Unlimited! Embark on an interactive journey to master mathematics like never before. Our student-centered platform puts the power in your hands, allowing you to explore and solve problems with values of your choice.
            </div>
            <div className="flex flex-col md:flex-row gap-5">

                <div className="basis-1/2 p-5 flex flex-col gap-3 border border-borderColor rounded-lg">

                    <div>
                        Why Math Unlimited?
                    </div>
                    <div className="pl-5">
                        🚀 Truly Unlimited: No limits, no boundaries! Explore solutions for any number of values, making learning as expansive as your curiosity.
                    </div>
                    <div className="pl-5">
                        🎨 Visualize Brilliance: Geometric and algebraic concepts come to life in their truest form. Witness the magic of visualization that transforms abstract ideas into a concrete understanding.
                    </div>
                    <div className="pl-5">
                        🌐 Multilingual Magic: Bridging language gaps, our applets are available in both the captivating realms of Kannada and English. Math is a universal language, and we speak it in your preferred dialect.
                    </div>
                    <div>
                        Join Math Unlimited – Where Math Knows No Bounds!
                    </div>

                </div>
                <div className="basis-1/2 p-5 flex flex-col items-center justify-center gap-3 border border-borderColor rounded-lg">
                    <div className="md:font-bold font-semi-bold">Sign in to start learning!</div>
                    <GoogleButton
                        onClick={() => { handleGoogleSignIn() }}
                    />
                </div>
            </div>
        </div>
    )
};

export default Signin;