import React, { useEffect, useState } from "react";
import { UserAuth } from "../context/AuthContext";
import useGetChapters from "../hooks/useGetChapters";
import useUpdateStorageFile from "../hooks/useUpdateStorageFile";
import useFileUploader from "../hooks/useFileUploader";

const Admin = () => {
    const { user } = UserAuth();

    const { getChaptersList, chaptersList, loading } = useGetChapters();
    const { uploadFile, loading: isfileUploaded } = useFileUploader();

    const [ggbFile, setggbFile] = useState<any>(null);

    const [languageDrop, setLanguageDrop] = useState(false)
    const [selectedLanguage, setSelectedLanguage] = useState("English");

    const [topicDrop, setTopicDrop] = useState(false)
    const [selectedTopic, setSelectedTopic] = useState<any>(null);

    const [subTopicDrop, setSubTopicDrop] = useState(false)
    const [selectedSubTopic, setSelectedSubTopic] = useState<any>(null);

    useEffect(() => {
        getChaptersList(selectedLanguage === "English" ? false : true);
    }, [selectedLanguage]);

    const setDropdowns = () => {
        setLanguageDrop(false);
        setTopicDrop(false);
        setSubTopicDrop(false);
    }

    // const { updateGGBFileData, updateGGBFile, loading: dfloading } = useUpdateStorageFile();
    const handleClick = () => {
        if (ggbFile == null) return;
        const langDir = selectedLanguage === "Kannada" ? "Kannada/Math_10th/" : "Math_10th/"
        // const response = updateGGBFileData(langDir + selectedTopic.GGBPath, selectedSubTopic.image_path, ggbFile);
        uploadFile(langDir, selectedTopic.GGBPath + selectedSubTopic.image_path, ggbFile)
    }

    // console.log(updateGGBFile);

    if (user.email === 'onkarmadli@gmail.com' || user.email === "malinisamrudh@gmail.com") {
        return (
            <div className="p-5 flex flex-col gap-2">
                <div className="flex gap-6">
                    <h3>Language:</h3>

                    <div>
                        <button onClick={() => {
                            setDropdowns();
                            setLanguageDrop(prev => !prev)

                        }} className="border border-black px-4 py-1">{selectedLanguage === "" ? "Select a Topic" : selectedLanguage}</button>
                        {languageDrop &&
                            <div className="absolute border border-black bg-white">
                                <h3 onClick={() => { setSelectedLanguage("English"); setSelectedTopic(null); setSelectedSubTopic(null); setDropdowns() }} className="cursor-pointer px-4 py-1 hover:bg-slate-300">English</h3>
                                <h3 onClick={() => { setSelectedLanguage("Kannada"); setSelectedTopic(null); setSelectedSubTopic(null); setDropdowns() }} className="cursor-pointer px-4 py-1 hover:bg-slate-300">Kannada</h3>
                            </div>
                        }
                    </div>
                </div>
                {loading ? "Loading..." :
                    <>
                        <div className="flex gap-6">
                            <h3>Topic:</h3>
                            <div>
                                <button onClick={() => {
                                    setDropdowns();
                                    setTopicDrop(prev => !prev)
                                }
                                } className="border border-black px-4 py-1">{selectedTopic === null ? "Select a Topic" : selectedTopic?.name}</button>
                                {topicDrop &&
                                    <div className="absolute border border-black bg-white">
                                        {chaptersList?.sort((a, b) => a.chapter_id - b.chapter_id).map((doc: any, index: number) => {
                                            const docData = doc;
                                            return (
                                                <h3 key={docData.chapter_id} onClick={() => { setSelectedTopic(docData); setSelectedSubTopic(null); setDropdowns() }} className="cursor-pointer px-4 py-1 hover:bg-slate-300">{docData.name}</h3>
                                            )
                                        })}
                                    </div>
                                }
                            </div>
                        </div>
                        {selectedTopic !== null && <div className="flex gap-6">
                            <h3>Sub Topic:</h3>
                            <div>
                                <button onClick={() => {
                                    setDropdowns();
                                    setSubTopicDrop(prev => !prev)
                                }
                                } className="border border-black px-4 py-1">{selectedSubTopic === null ? "Select a SubTopic" : selectedSubTopic?.name}</button>
                                {subTopicDrop &&
                                    (selectedTopic?.topics[0]?.sub_id === undefined || selectedTopic?.topics[0]?.sub_id === null ?
                                        <div className="absolute border border-black bg-white">
                                            {selectedTopic.topics.map((subTopic: any, index: number) => {
                                                return (
                                                    <h3 key={selectedTopic.chapter_id + '_' + subTopic.id} onClick={() => { setSelectedSubTopic(subTopic); setDropdowns() }} className="cursor-pointer px-4 py-1 hover:bg-slate-300">{subTopic.name}</h3>
                                                )
                                            })}
                                        </div>
                                        :
                                        <div className="absolute">
                                            <div className="border border-black bg-white">
                                                <h2>{selectedLanguage === "English" ? "Practical" : "ಪ್ರಾಯೋಗಿಕ ಕಲಿಕೆ"}</h2>
                                                {selectedTopic?.topics.filter((subTopic) => subTopic.sub_id == 1).map((docSubTopic, topicIndex: number) => {
                                                    return (
                                                        <h3 key={selectedTopic.chapter_id + '_' + docSubTopic.id} onClick={() => { setSelectedSubTopic(docSubTopic); setDropdowns() }} className="cursor-pointer px-4 py-1 hover:bg-slate-300">{docSubTopic.name}</h3>
                                                    )
                                                })}
                                            </div>
                                            <div className="border border-black bg-white">
                                                <h2>{selectedLanguage === "English" ? "Theoretical Proofs" : "ತಾರ್ಕಿಕ ಸಾಧನೆ"}</h2>
                                                {selectedTopic?.topics.filter((subTopic) => subTopic.sub_id == 2).map((docSubTopic, topicIndex: number) => {
                                                    return (
                                                        <h3 key={selectedTopic.chapter_id + '_' + docSubTopic.id} onClick={() => { setSelectedSubTopic(docSubTopic); setDropdowns() }} className="cursor-pointer px-4 py-1 hover:bg-slate-300">{docSubTopic.name}</h3>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>}
                        {
                            selectedSubTopic !== null && selectedSubTopic !== null &&
                            <div className="flex gap-6">
                                <input type="file" onChange={(e: any) =>
                                    setggbFile(e.target.files[0])} />
                                <button onClick={handleClick} className="border border-black px-4 py-1 rounded-md hover:bg-slate-400">Upload</button>

                            </div>
                        }
                        {/* {updateGGBFile === true ? <h3>Success</h3> : <h3>Uploading...</h3>} */}
                    </>
                }
            </div>
        )
    } else {
        return (
            <div>
                Access Denied!
            </div>
        )
    }
}

export default Admin;