import { useEffect, useState } from "react";
import getChapters from "../firestore/getChapters";
import axios from "axios";
import { UserAuth } from "../context/AuthContext";

const useGetChapters = () => {
  const [chaptersList, setChaptersList] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { sessionToken } = UserAuth();
  const getChaptersList = async (kannada: boolean) => {
    try {
      setLoading(true);
      // if (kannada) {
      //   const data = await getChapters(true);
      //   setChaptersList(data);
      // } else {
      //   const data = await getChapters(false);
      //   setChaptersList(data);
      // }
      if (kannada) {
        // axios.defaults.withCredentials = true
        const chaptersList = await axios.get(
          process.env.FIREBASE_GET_KANNADA_CHAPTER_URL + "",{
            headers: {
              Authorization: "Bearer " + sessionToken,
            },
          }
        );
        setChaptersList(chaptersList.data);
      } else {
        const chaptersList = await axios.get(
          process.env.FIREBASE_GET_CHAPTER_URL + "",{
          // process.env.FIREBASE_FUNCTION_URL + "/chapters",{

            headers: {
              Authorization: "Bearer " + sessionToken,
            },
          }
        );
        setChaptersList(chaptersList.data);
      }
    } catch (error) {
      if(error.response.status === 401){
        setChaptersList(error.response.data)
      }
      console.log("Error fetching chapters");
    } finally {
      setLoading(false);
    }
  };

  return { getChaptersList, chaptersList, loading };
};

export default useGetChapters;
