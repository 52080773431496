import { useRouteError } from "react-router-dom";

const ErrorPage = () =>{
    const err= useRouteError();
    return(
        <>
            {err.status}: {err.statusText}
        </>
    )
};

export default ErrorPage;