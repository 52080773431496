import { db, storage } from "../pages/firebase";
import {
  collection,
  query,
  where,
  getDocs,
  limit,
  addDoc,
} from "firebase/firestore";
import {
  ref,
  getDownloadURL,
  deleteObject,
  uploadBytes,
  uploadString
} from "firebase/storage";

export const getCollectionRef = async (collectionName: string) => {
  const collectionRef = collection(db, collectionName);
  return collectionRef;
};

export const getUserDoc = async (collection, email: string) => {
  const q = query(collection, where("email", "==", email), limit(1));
  const querySnapshot = await getDocs(q);
  if (querySnapshot.size === 1) {
    return querySnapshot.docs;
  } else {
    return null;
  }
};

export const addToCollection = async (collectionRef, data) => {
  await addDoc(collectionRef, data);
};

export const getChaptersData = async (collectionRef) => {
  const chaptersList = await getDocs(collectionRef);
  return chaptersList.docs;
};

export const getStorageReference = async (filePath: string) => {
  try {
    const storageRef = ref(storage, "MathUnlimited/" + filePath);
    return storageRef;
  } catch (e) {
    console.log("Error fetching storage Ref", e);
    return null;
  }
};

export const getStorageURL = (storageRef) => {
  return getDownloadURL(storageRef);
};

export const deleteStorageFile = async (storageRef) => {
  try {
    const deletedObject = await deleteObject(storageRef);
  } catch (e) {
    return(e.code);
  }
  return deleteObject;
};

export const uploadStorageFile = async (storageRef, file) => {
  // const uploadFile = uploadBytes(storageRef, file);
  const metadata = {
    contentType: "application/octet-stream"
  }
  const uploadFile = await uploadBytes(storageRef, file, metadata);
};
