import React, { useEffect, useState } from "react";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import useGetPaymentReq from "../hooks/useGetPaymentReq";

const Checkout = () => {
    const [phone, setPhone] = useState("");
    const { getPaymentRequest, resp, loading } = useGetPaymentReq();
    const handleInputChange = (event) => {
        setPhone(event.target.value);
    };
    
    const handleBuyButton = (subType:string) => {
        const isPhoneNumber = (str: string) => /^\d{10}$/.test(str);
        if(isPhoneNumber(phone)){
            getPaymentRequest(phone, subType)
        }
    };

    useEffect(()=>{
        if(!loading && resp && resp.longurl){
            window.open(resp.longurl, '_blank');
        }
    },[loading])

    return (
        <div className="p-10 flex flex-col gap-10 items-center">
            <div className="text-2xl font-bold">Check Out Page</div>
            <div className="flex flex-col items-center">
                <label htmlFor="small-input" className="block mb-2 text-sm font-medium text-gray-900">Enter Phone Number</label>
                <input type="text" id="small-input" className="block w-full p-2 text-gray-900 border border-gray-300 rounded-md bg-gray-50 text-xs focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    value={phone}
                    onChange={handleInputChange}
                />
            </div>

            <div className="flex flex-col md:flex-row gap-10">
                <div className="flex flex-col items-center justify-between gap-3 border border-borderColor rounded-md p-5">
                    <div className="text-2xl text-gray-600 font-bold">Monthly Subscription</div>
                    <div className="flex gap-3"><s>₹299</s> <div className="text-2xl">{`₹199`}</div> </div>
                    {`(Introductory Offer)`}
                    <div>
                        <ul>
                            <li className="flex gap-1"><div className="h-6 w-6"><CheckCircleIcon /></div>Full access to Math Unlimited</li>
                        </ul>
                    </div>
                    <button className="bg-green-500 rounded-md px-4 py-1 w-full" onClick={
                        ()=>{
                            handleBuyButton("month")
                        }
                    }>Buy Now</button>
                </div>
                <div className="flex flex-col items-center justify-between gap-3 border border-borderColor rounded-md p-5">
                    <div className="text-2xl text-gray-600 font-bold">Yearly Subscription</div>
                    <div className="text-2xl">₹2499</div>
                    <div>
                        <ul>
                            <li className="flex gap-1"><div className="h-6 w-6"><CheckCircleIcon /></div>Full access to Math Unlimited</li>
                        </ul>
                    </div>
                    <button className="bg-green-500 rounded-md px-4 py-1 w-full" onClick={
                        ()=>{
                            handleBuyButton("year")
                        }
                    }>Buy Now</button>
                </div>
            </div>
        </div>)
}

export default Checkout;