import React from "react";

const Modal = ({visible, onClose, content}) => {

    const handleClose = (e) => {
        if(e.target.id === "modalContainer")
            onClose();
    }
    if(visible)
    return (
        <div id="modalContainer" onClick={handleClose} className="w-full h-full fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center z-10">
            <div className="w-[25vw] h-[25vh] bg-white">
                    {content}
            </div> 
        </div>)
}

export default Modal;