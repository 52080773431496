import { useState } from "react";
import axios from "axios";
import { UserAuth } from "../context/AuthContext";

const useGetPaymentReq = () => {
  const [resp, setResp] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { sessionToken } = UserAuth();
  const getPaymentRequest = async (phone: string, subType: string) => {
    try {
      setLoading(true);

      const paymentURL = await axios.post(
        process.env.FIREBASE_GET_PAYMENT_URL + "",
        {
          phone: phone,
          subType: subType,
        },
        {
          headers: {
            Authorization: "Bearer " + sessionToken,
          },
        }
      );

      setResp(paymentURL.data);
    } catch (error) {
        console.error(error)
      if (error.response.status === 401) {
        setResp(error.response.data);
      }
      console.log("Error fetching payment details!");
    } finally {
      setLoading(false);
    }
  };

  return { getPaymentRequest, resp, loading };
};

export default useGetPaymentReq;
